import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserSecret,
  faUsers,
  faUserPlus,
  faBuildingColumns,
  faGraduationCap,
  faSuitcase,
  faBookmark,
  faTree,
  faBell,
  faTruckFast,
  faHouse,
  faAward,
  faFlag,
  faFolderOpen,
  faCartShopping,
  faBook,
  faEnvelope,
  faBellSlash,
  faWifi,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import image1 from "../../src/assets/images/image1.webp";
import image2 from "../../src/assets/images/image2.webp";
import image3 from "../../src/assets/images/image3.webp";
import image4 from "../../src/assets/images/image4.webp";
import image5 from "../../src/assets/images/image5.webp";
import Carousel from "react-material-ui-carousel";
import service1 from "../../src/assets/images/service-1.png";
import service2 from "../../src/assets/images/service-2.png";
import service3 from "../../src/assets/images/service-3.png";
import service4 from "../../src/assets/images/service-4.png";
import service5 from "../../src/assets/images/service-5.png";
import service6 from "../../src/assets/images/service-6.png";

import client from "../../src/assets/images/client-6.webp";
import schoolgirl from "../../src/assets/images/banner-image.png";
import admission from "../../src/assets/images/admission.jpeg";

import vedioimage from "../../src/assets/images/carousel-2.png";

const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};
const data = [
  {
    id: 1,
    description:
      "Absolutely thrilled with the attention to detail and care given to my child at Podar Prep Yelahanka. The staff goes above and beyond to ensure a nurturing environment for learning.",
    profile: client,
    name: "Druthi",
    // designation: "designation",
  },
  {
    id: 2,
    description:
      "Impressed by the facilities and curriculum offered at Podar Prep Yelahanka. My child looks forward to going to school every day, which speaks volumes about the positive experience provided.",
    profile: client,
    name: "Suhasini",
    // designation: "designation",
  },
  {
    id: 3,
    description:
      "Podar Prep Yelahanka has exceeded our expectations in terms of both academics and extracurricular activities. It's wonderful to see my child thriving in such a supportive environment.",
    profile: client,
    name: "Mr Gowda",
    // designation: "designation",
  },
  {
    id: 4,
    description:
      "As a parent, I couldn't be happier with the progress my child has made at Podar Prep Yelahanka. The teachers are dedicated and caring, and it's evident that they have the best interests of the children at heart.",
    profile: client,
    name: "Santhosh",
    // designation: "designation",
  },
  {
    id: 5,
    description:
      "Podar Prep Yelahanka has been a fantastic choice for our family. The emphasis on holistic development and the personalized attention given to each child make it stand out among other preschools.",
    profile: client,
    name: "Gowtham",
    // designation: "designation",
  },
  {
    id: 6,
    description:
      "The facilities at Podar Prep Yelahanka are top-notch, and the staff is incredibly friendly and approachable. It's reassuring to know that my child is in such capable hands.",
    profile: client,
    name: "Shewtha",
    // designation: "designation",
  },
  {
    id: 7,
    description:
      "My child has blossomed since starting at Podar Prep Yelahanka. The inclusive environment and engaging activities have helped boost their confidence and social skills.",
    profile: client,
    name: "Mounika",
    // designation: "designation",
  },
  {
    id: 8,
    description:
      "Podar Prep Yelahanka has created a warm and welcoming atmosphere that makes both children and parents feel at ease. It's clear that they genuinely care about the well-being and success of every child.",
    profile: client,
    name: "Usha",
    // designation: "designation",
  },
];
const chunkedData = chunkArray(data, 2);
const HomePage = ({ onApplyNowClick }) => {
  const [playVideo, setPlayVideo] = useState(false);

  const handlePlayButtonClick = () => {
    setPlayVideo(true);
  };

  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const mobileScreen = useMediaQuery(
    "(min-width: 360px) and (max-width: 599px)"
  );
  const laptopScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1440px)"
  );
  const imageScreenOne = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1340px)"
  );
  const imageScreenTwo = useMediaQuery(
    "(min-width: 1341px) and (max-width: 1440px)"
  );
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setPhone(numericValue);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const ipInfoResponse = await fetch("https://ipinfo.io/json");
      const ipInfo = await ipInfoResponse.json();

      const data = {
        fullName: name,
        phoneNumber: phone,
        ip: ipInfo.ip,
        city: ipInfo.city,
        region: ipInfo.region,
        country: ipInfo.country,
        loc: ipInfo.loc,
      };

      const response = await fetch(
        "https://api.podarprepyelahanka.com/addLead",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      setName("");
      setPhone("");
      setMessage("");
    } catch (error) {}
  };
  const handleButtonClick = (index) => {
    setActiveButtonIndex(index);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveButtonIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 6000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <Grid>
        <Grid
          id="home"
          container
          md={12}
          sx={{
            display: "flex",
            flexDirection: mobileScreen ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",

            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            item
            md={7.5}
            xs={12}
            sx={{
              display: "flex",
              mt: mobileScreen ? 0 : 15,
              my: mobileScreen ? 1 : 0,
              alignItems: "center",
              justifyContent: "center",
              color: "#000",
            }}
          >
            <Grid
              sx={{
                px: mobileScreen ? 0 : laptopScreen ? 0 : 2,
                width: mobileScreen
                  ? 300
                  : imageScreenOne
                  ? 920
                  : imageScreenTwo
                  ? 750
                  : 880,
                height: mobileScreen
                  ? 200
                  : imageScreenOne
                  ? 520
                  : imageScreenTwo
                  ? 520
                  : "100%",
                mb: mobileScreen
                  ? 2
                  : imageScreenOne
                  ? -2
                  : imageScreenTwo
                  ? -2.3
                  : -2.9,
              }}
            >
              <img
                src={schoolgirl}
                alt="schoolgirlimage"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: mobileScreen
                    ? "contain"
                    : laptopScreen
                    ? "cover"
                    : "cover",
                }}
              />
            </Grid>
            <Grid
              sx={{
                ml: mobileScreen ? 0 : laptopScreen ? 5 : 10,
                mr: mobileScreen ? 0 : laptopScreen ? 6 : 12,

                backgroundColor: "rgba(255, 255, 255, 0.9)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
                px: mobileScreen ? 1 : 0,
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: mobileScreen ? 18 : laptopScreen ? 35 : 44,
                  fontFamily: '"Cabin Sketch", cursive',
                  fontWeight: 600,
                }}
              >
                Welcome to PodarPrep
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: mobileScreen ? 16 : laptopScreen ? 32 : 42,
                  fontWeight: "bold",
                  color: "#2E65AD",
                  py: mobileScreen ? "2px" : 1,
                  fontFamily: '"Cabin Sketch", cursive',
                }}
              >
                Largest PreSchool in Yelahanka.
              </Typography>

              <Typography
                varient="p"
                sx={{
                  fontSize: mobileScreen ? 12 : laptopScreen ? 16 : 18,
                  py: mobileScreen ? "2px" : 2,
                  textAlign: "justify",
                }}
              >
                Join the fun-filled journey of learning and laughter at
                Yelahanka's biggest Podar Prep preschool, where every day is an
                exciting new chapter for your little one.
              </Typography>
              <Typography
                varient="p"
                sx={{
                  display: mobileScreen ? "none" : "block",
                  fontSize: mobileScreen ? 15 : laptopScreen ? 16 : 18,
                  py: mobileScreen ? 1 : 2,
                  textAlign: "justify",
                }}
              >
                Witness the laughter, the learning, and the love that fill our
                halls, inviting you to be a part of something truly
                extraordinary. Join us on this enchanting adventure and see why
                our school is more than just a place—it's a home for young minds
                to thrive.
              </Typography>

              <Button
                sx={{
                  my: mobileScreen ? 1 : laptopScreen ? 1 : 2,
                  color: "#fff",
                  background: "#2E65AD",
                  borderRadius: 0,
                  py: mobileScreen ? 1 : laptopScreen ? 1 : 2,
                  px: mobileScreen ? 1 : 2,
                  "&:hover": {
                    color: "#fff",
                    background: "#2E65AD",
                  },
                  fontSize: mobileScreen ? 10 : laptopScreen ? 14 : 16,
                  fontWeight: 600,
                }}
                onClick={onApplyNowClick}
              >
                <FontAwesomeIcon icon={faBook} />
                &nbsp; Apply Now!
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            md={3.5}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              py: mobileScreen ? 1 : laptopScreen ? 2 : 3,
              mx: mobileScreen ? 2 : laptopScreen ? 1 : 0,
            }}
          >
            <Grid
              sx={{
                p: mobileScreen ? 3 : laptopScreen ? 3 : 6,

                boxShadow: mobileScreen ? 3 : 5,
                borderRadius: 3,
                background: "#fff",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontSize: mobileScreen ? 20 : 30 }}
                >
                  Admission&nbsp;
                  <span style={{ color: "#2E65AD" }}>Form</span>
                </Typography>

                <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
                  <Divider
                    sx={{
                      background: "#2E65AD",
                      width: 150,
                      textAlign: "center",
                    }}
                  />
                </Grid>
                <Typography
                  variant="p"
                  sx={{ fontSize: mobileScreen ? 16 : 18, textAlign: "center" }}
                >
                  Join the Podar Prep Family - Where Learning Feels Like Fun!
                </Typography>
                <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
                  <Divider
                    sx={{
                      background: "#2E65AD",
                      width: 150,

                      textAlign: "center",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: mobileScreen ? 2 : laptopScreen ? 1 : 5,
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={mobileScreen ? 2 : laptopScreen ? 2 : 4}
                    sx={{
                      width: mobileScreen ? 250 : laptopScreen ? 400 : 500,
                      height: 280,
                    }}
                  >
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Enter Your Name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        inputProps={{
                          style: {
                            height: mobileScreen ? 5 : laptopScreen ? 10 : 20,
                            fontSize: mobileScreen
                              ? 14
                              : laptopScreen
                              ? 15
                              : 16,

                            borderRadius: 0,
                          },
                        }}
                        variant="outlined"
                        sx={{
                          margin: 0,
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Enter Your PhoneNo"
                        type="tel"
                        required
                        value={phone}
                        onChange={handlePhoneChange}
                        inputProps={{
                          maxLength: 10,
                          style: {
                            height: mobileScreen ? 5 : laptopScreen ? 10 : 20,
                            fontSize: mobileScreen
                              ? 14
                              : laptopScreen
                              ? 15
                              : 16,

                            borderRadius: 0,
                          },
                        }}
                        variant="outlined"
                        sx={{
                          margin: 0,
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Enter Your Message"
                        value={message}
                        // onChange={(e) => setMessage(e.target.value)}
                        multiline
                        rows={4}
                        // required
                        inputProps={{
                          style: {
                            fontSize: mobileScreen
                              ? 14
                              : laptopScreen
                              ? 15
                              : 16,

                            borderRadius: 0,
                          },
                        }}
                        variant="outlined"
                        sx={{
                          margin: 0,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: mobileScreen ? 30 : laptopScreen ? 40 : 50,
                          fontWeight: 600,
                          width: mobileScreen ? 150 : "100%",
                          borderRadius: 0,
                          background: "#2E65AD",
                          "&:hover": {
                            background: "#2E65AD",
                          },
                          fontSize: mobileScreen ? 12 : laptopScreen ? 14 : 16,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{
                            marginRight: 8,
                            fontSize: mobileScreen
                              ? 14
                              : laptopScreen
                              ? 16
                              : 20,
                          }}
                        />
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            {/* <Grid sx={{ width: 600, height: 400 }}>
              <img
                src={admissionbanner}
                alt="admissionbanner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Grid> */}
          </Grid>
        </Grid>
        {/* about school */}
        <Grid
          id="about"
          sx={{
            background: "#f5f5f5",
            p: mobileScreen ? 2 : laptopScreen ? 5 : 10,
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: mobileScreen ? 20 : 40 }}>
              <span style={{ color: "#2E65AD" }}>About</span> our School
            </Typography>

            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Join the Vibrant Community of Our "Yelahanka's Largest Preschool
              Today"!
            </Typography>
            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,

                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>

          <Grid sx={{ py: 2 }}>
            <Grid
              container
              sx={{
                display: "flex",

                alignItems: "center",
                justifyContent: "center",
                gap: 5,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                }}
              >
                <Grid
                  sx={{
                    background: "#f5f5f5",
                    width: 75,
                    height: 75,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    mb: 2,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFlag}
                    style={{ color: "#2E65AD", fontSize: 35 }}
                  />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  Clean Environment
                </Typography>
                <Typography
                  sx={{ textAlign: "center", color: "#777777", fontSize: 14 }}
                >
                  State-of-the-Art Facilities - Our preschool boasts modern
                  amenities designed to inspire and engage young learners.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                }}
              >
                <Grid
                  sx={{
                    background: "#f5f5f5",
                    width: 75,
                    height: 75,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    mb: 2,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFolderOpen}
                    style={{ color: "#2E65AD", fontSize: 35, py: 3 }}
                  />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  BIG PLAYGROUND & AREA
                </Typography>
                <Typography
                  sx={{ textAlign: "center", color: "#777777", fontSize: 14 }}
                >
                  Expansive Playgrounds - Where laughter echoes and friendships
                  blossom, our vast outdoor spaces offer endless opportunities
                  for active play and exploration.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                }}
              >
                <Grid
                  sx={{
                    background: "#f5f5f5",
                    width: 75,
                    height: 75,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    mb: 2,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    style={{ color: "#2E65AD", fontSize: 35, py: 3 }}
                  />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  ARRANGE ROOMS
                </Typography>
                <Typography
                  sx={{ textAlign: "center", color: "#777777", fontSize: 14 }}
                >
                  Thoughtfully Designed Classrooms: From cozy reading corners to
                  dynamic play zones, our carefully arranged rooms provide
                  stimulating environments for every aspect of your child's
                  development.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Our school with video */}
        <Grid sx={{ p: mobileScreen ? 2 : laptopScreen ? 5 : 10 }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: mobileScreen ? 20 : 40 }}>
              Our school with <span style={{ color: "#2E65AD" }}>video</span>
            </Typography>

            <Grid sx={{ py: 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Step Inside the Magic - Explore Our School Through the Lens of
              Learning.
            </Typography>
            <Grid>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,
                  my: 3,
                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              md={5.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                mx: 5,
              }}
            >
              <Grid
                sx={{
                  position: "relative",
                  width: mobileScreen ? 300 : 600,
                  height: mobileScreen ? 200 : 400,
                }}
              >
                {!playVideo ? (
                  <>
                    <img
                      src={vedioimage}
                      alt="video thumbnail"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <IconButton
                      onClick={handlePlayButtonClick}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        backgroundColor: "rgba(255,255,255,0.6)",
                        "&:hover": {
                          backgroundColor: "rgba(255,255,255,0.8)",
                        },
                        width: 70,
                        height: 70,
                        fontSize: mobileScreen ? 20 : 40,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlay}
                        style={{ color: "#2E65AD" }}
                      />
                    </IconButton>
                  </>
                ) : (
                  <iframe
                    src="https://www.youtube.com/embed/OJX2Kb03xD0?autoplay=1&mute=1&controls=1&rel=0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                )}
              </Grid>
            </Grid>

            <Grid item md={5.5}>
              <Grid
                sx={{
                  width: mobileScreen ? 300 : 500,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: mobileScreen ? 18 : 20, py: 2 }}
                >
                  Witness the laughter, the learning, and the love that fill our
                  halls, inviting you to be a part of something truly
                  extraordinary. Join us on this enchanting adventure and see
                  why our school is more than just a place—it's a home for young
                  minds to thrive.
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 1,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBellSlash}
                    style={{
                      color: "#2E65AD",
                      fontSize: mobileScreen ? 18 : 22,
                      py: 1,
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: mobileScreen ? 18 : 20,
                      px: mobileScreen ? 2 : 2,
                    }}
                  >
                    Modern Learning Environments.
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 1,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faWifi}
                    style={{
                      color: "#2E65AD",
                      fontSize: mobileScreen ? 18 : 22,
                      py: 1,
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: mobileScreen ? 18 : 20,
                      px: mobileScreen ? 2 : 2,
                    }}
                  >
                    Spacious Outdoor Playgrounds.
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 1,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFlag}
                    style={{
                      color: "#2E65AD",
                      fontSize: mobileScreen ? 18 : 22,
                      py: 1,
                    }}
                  />

                  <Typography
                    variant="p"
                    sx={{
                      fontSize: mobileScreen ? 18 : 20,
                      px: mobileScreen ? 2 : 2,
                    }}
                  >
                    Supportive, Nurturing Atmosphere.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Some facts of our school */}
        <Grid
          sx={{
            p: mobileScreen ? 2 : laptopScreen ? 5 : 10,
            // backgroundImage: `url(${backgroundImage1})`,
            background: "#F6D74E",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "400px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: mobileScreen ? 20 : 40 }}>
              Some <span style={{ color: "#2E65AD" }}>facts</span> of our school
            </Typography>

            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Discover the Wonders - Unveiling Our School's Marvels.
            </Typography>
            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,

                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",

              justifyContent: "center",
              gap: mobileScreen ? 2 : 4,
            }}
          >
            <Grid
              item
              md={1.5}
              xs={5}
              sx={{
                py: 5,
                width: 175,
                height: 200,
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: 1,
                color: "#000",
              }}
            >
              <FontAwesomeIcon icon={faUsers} style={{ fontSize: 40 }} />
              <Typography sx={{ color: "#2E65AD", fontSize: 40 }}>
                1200
              </Typography>
              <Typography sx={{ fontSize: 20 }}>Students</Typography>
            </Grid>

            <Grid
              item
              md={1.5}
              xs={5}
              sx={{
                py: 5,
                width: 175,
                height: 200,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <FontAwesomeIcon icon={faUserSecret} style={{ fontSize: 40 }} />
              <Typography sx={{ color: "#2E65AD", fontSize: 40 }}>
                12
              </Typography>
              <Typography sx={{ fontSize: 20 }}>Teachers</Typography>
            </Grid>
            <Grid
              item
              md={1.5}
              xs={5}
              sx={{
                py: 5,
                width: 175,
                height: 200,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: 40 }} />
              <Typography sx={{ color: "#2E65AD", fontSize: 40 }}>
                700
              </Typography>
              <Typography sx={{ fontSize: 20 }}>Average A+</Typography>
            </Grid>
            <Grid
              item
              md={1.5}
              xs={5}
              sx={{
                py: 5,
                width: 175,
                height: 200,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <FontAwesomeIcon
                icon={faBuildingColumns}
                style={{ fontSize: 40 }}
              />
              <Typography sx={{ color: "#2E65AD", fontSize: 40 }}>
                50
              </Typography>
              <Typography sx={{ fontSize: 20 }}>Rooms</Typography>
            </Grid>
            <Grid
              item
              md={1.5}
              xs={5}
              sx={{
                py: 5,
                width: 175,
                height: 200,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <FontAwesomeIcon
                icon={faGraduationCap}
                style={{ fontSize: 40 }}
              />
              <Typography sx={{ color: "#2E65AD", fontSize: 40 }}>
                263
              </Typography>
              <Typography sx={{ fontSize: 20 }}>Awards</Typography>
            </Grid>

            <Grid
              item
              md={1.5}
              xs={5}
              sx={{
                py: 5,
                width: 175,
                height: 200,
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
                color: "#000",
              }}
            >
              <FontAwesomeIcon icon={faSuitcase} style={{ fontSize: 40 }} />
              <Typography sx={{ color: "#2E65AD", fontSize: 40 }}>
                56
              </Typography>
              <Typography sx={{ fontSize: 20 }}>Courses</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Key Pillars of Our Preschool Experience*/}
        <Grid
          id="pillars"
          sx={{
            background: "#f5f5f5",
            p: mobileScreen ? 2 : laptopScreen ? 5 : 8,
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: mobileScreen ? 20 : 40, textAlign: "center" }}
            >
              Key Pillars of Our&nbsp;
              <span style={{ color: "#2E65AD" }}>Preschool Experience</span>
            </Typography>

            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Pillars of Promise - Building Bright Futures Together.
            </Typography>
            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,

                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                width: "90%",

                borderRadius: 4,

                my: mobileScreen ? 2 : laptopScreen ? 2 : 5,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: mobileScreen ? 2 : 4,
                }}
              >
                <Grid>
                  <Grid
                    onClick={() => handleButtonClick(0)}
                    sx={{
                      width: "auto",
                      gap: 1,
                      height: mobileScreen ? 45 : 100,
                      py: mobileScreen ? 1 : 2,
                      px: mobileScreen ? 1 : 4,
                      textAlign: "center",
                      display: "flex",
                      background: activeButtonIndex === 0 ? "#2E65AD" : "#FFF",
                      color: activeButtonIndex === 0 ? "#FFF" : "#555555",

                      flexDirection: "column",
                      alignItems: "center",

                      "&:hover": {
                        color: activeButtonIndex === 0 ? "#FFF" : "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBookmark}
                      style={{ fontSize: mobileScreen ? 10 : 28 }}
                    />

                    <Typography sx={{ fontSize: mobileScreen ? 10 : 20 }}>
                      Innovative
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid
                    onClick={() => handleButtonClick(1)}
                    sx={{
                      width: "auto",
                      gap: 1,
                      height: mobileScreen ? 45 : 100,
                      py: mobileScreen ? 1 : 2,
                      px: mobileScreen ? 1 : 4,
                      textAlign: "center",
                      display: "flex",
                      background: activeButtonIndex === 1 ? "#2E65AD" : "#FFF",
                      color: activeButtonIndex === 1 ? "#FFF" : "#555555",
                      flexDirection: "column",
                      alignItems: "center",
                      "&:hover": {
                        color: activeButtonIndex === 1 ? "#FFF" : "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTree}
                      style={{ fontSize: mobileScreen ? 10 : 28 }}
                    />

                    <Typography sx={{ fontSize: mobileScreen ? 10 : 20 }}>
                      Nurturing
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid
                    onClick={() => handleButtonClick(2)}
                    sx={{
                      width: "auto",
                      gap: 1,
                      height: mobileScreen ? 45 : 100,
                      py: mobileScreen ? 1 : 2,
                      px: mobileScreen ? 1 : 4,
                      textAlign: "center",
                      display: "flex",
                      background: activeButtonIndex === 2 ? "#2E65AD" : "#FFF",
                      color: activeButtonIndex === 2 ? "#FFF" : "#555555",
                      flexDirection: "column",
                      alignItems: "center",
                      "&:hover": {
                        color: activeButtonIndex === 2 ? "#FFF" : "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ fontSize: mobileScreen ? 10 : 28 }}
                    />
                    <Typography sx={{ fontSize: mobileScreen ? 10 : 20 }}>
                      Inspiring
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  onClick={() => handleButtonClick(3)}
                  sx={{
                    width: "auto",

                    gap: 1,
                    height: mobileScreen ? 45 : 100,
                    py: mobileScreen ? 1 : 2,
                    px: mobileScreen ? 1 : 4,
                    textAlign: "center",
                    display: "flex",
                    background: activeButtonIndex === 3 ? "#2E65AD" : "#FFF",
                    color: activeButtonIndex === 3 ? "#FFF" : "#555555",
                    flexDirection: "column",
                    alignItems: "center",
                    "&:hover": {
                      color: activeButtonIndex === 3 ? "#FFF" : "#2E65AD",
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTruckFast}
                    style={{ fontSize: mobileScreen ? 10 : 28 }}
                  />
                  <Typography sx={{ fontSize: mobileScreen ? 10 : 20 }}>
                    Dynamic
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => handleButtonClick(4)}
                  sx={{
                    width: "auto",
                    height: mobileScreen ? 45 : 100,
                    py: mobileScreen ? 1 : 2,
                    px: mobileScreen ? 1 : 4,
                    gap: 1,
                    textAlign: "center",
                    display: "flex",
                    background: activeButtonIndex === 4 ? "#2E65AD" : "#fff",
                    color: activeButtonIndex === 4 ? "#FFF" : "#555555",
                    flexDirection: "column",
                    alignItems: "center",
                    "&:hover": {
                      color: activeButtonIndex === 4 ? "#FFF" : "#2E65AD",
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faHouse}
                    style={{ fontSize: mobileScreen ? 10 : 28 }}
                  />
                  <Typography sx={{ fontSize: mobileScreen ? 10 : 20 }}>
                    Engaging
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid>
                {activeButtonIndex === 0 && (
                  <Grid
                    container
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                      gap: mobileScreen ? 2 : 10,
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sx={{
                        width: 150,
                        height: 250,

                        py: { md: 0, xs: 2 },
                      }}
                    >
                      <img
                        src={image1}
                        alt="innovative"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#2E65AD",
                          fontSize: { md: 20, xs: 15, sm: 16 },

                          py: { md: 2, xs: 1, sm: 1 },
                          textTransform: "uppercase",
                          fontWeight: 600,
                        }}
                      >
                        Innovation:
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          color: "#393939",
                          fontSize: { md: 18, xs: 14, sm: 16 },
                          lineHeight: "22px",
                        }}
                      >
                        Innovation is the cornerstone of our approach, fostering
                        a culture of creativity and forward-thinking. Through
                        cutting-edge teaching methods and curriculum design, we
                        continuously strive to push boundaries and ignite
                        curiosity, preparing our students for a rapidly evolving
                        world.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {activeButtonIndex === 1 && (
                  <Grid
                    container
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                      gap: mobileScreen ? 2 : 10,
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sx={{
                        width: 150,
                        height: 250,

                        py: { md: 0, xs: 2 },
                      }}
                    >
                      <img
                        src={image2}
                        alt="nurturing"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#2E65AD",
                          fontSize: { md: 20, xs: 15, sm: 16 },

                          py: { md: 2, xs: 1, sm: 1 },
                          textTransform: "uppercase",
                          fontWeight: 600,
                        }}
                      >
                        Nurturing:
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          color: "#393939",
                          fontSize: { md: 18, xs: 14, sm: 16 },
                          lineHeight: "22px",
                        }}
                      >
                        Nurturing lies at the heart of our ethos, where every
                        child is embraced with warmth and support. We cultivate
                        a caring environment that nurtures not only academic
                        growth but also emotional well-being, fostering
                        confidence and resilience in our students.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {activeButtonIndex === 2 && (
                  <Grid
                    container
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                      gap: mobileScreen ? 2 : 10,
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sx={{
                        width: 150,
                        height: 250,

                        py: { md: 0, xs: 2 },
                      }}
                    >
                      <img
                        src={image3}
                        alt="Inspiring"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#2E65AD",
                          fontSize: { md: 20, xs: 15, sm: 16 },

                          py: { md: 2, xs: 1, sm: 1 },
                          textTransform: "uppercase",
                          fontWeight: 600,
                        }}
                      >
                        Inspiring:
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          color: "#393939",
                          fontSize: { md: 18, xs: 14, sm: 16 },
                          lineHeight: "22px",
                        }}
                      >
                        Inspiring our students to reach for the stars, we
                        cultivate an environment where curiosity thrives and
                        dreams take flight. Through captivating experiences and
                        role models, we ignite a passion for learning,
                        empowering each child to explore their potential and
                        pursue their aspirations.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {activeButtonIndex === 3 && (
                  <Grid
                    container
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                      gap: mobileScreen ? 2 : 10,
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sx={{
                        width: 150,
                        height: 250,

                        py: { md: 0, xs: 2 },
                      }}
                    >
                      <img
                        src={image4}
                        alt="Dynamic"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#2E65AD",
                          fontSize: { md: 20, xs: 15, sm: 16 },

                          py: { md: 2, xs: 1, sm: 1 },
                          textTransform: "uppercase",
                          fontWeight: 600,
                        }}
                      >
                        Dynamic:
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          color: "#393939",
                          fontSize: { md: 18, xs: 14, sm: 16 },
                          lineHeight: "22px",
                        }}
                      >
                        At our school, dynamism fuels every aspect of learning,
                        ensuring an environment that adapts and evolves with our
                        students. From interactive lessons to diverse
                        extracurricular activities, we embrace change and
                        innovation to keep education lively, relevant, and
                        engaging.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {activeButtonIndex === 4 && (
                  <Grid
                    container
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                      gap: mobileScreen ? 2 : 10,
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sx={{
                        width: 150,
                        height: 250,

                        py: { md: 0, xs: 2 },
                      }}
                    >
                      <img
                        src={image5}
                        alt="Engaging"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#2E65AD",
                          fontSize: { md: 20, xs: 15, sm: 16 },

                          py: { md: 2, xs: 1, sm: 1 },
                          textTransform: "uppercase",
                          fontWeight: 600,
                        }}
                      >
                        Engaging:
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          color: "#393939",
                          fontSize: { md: 18, xs: 14, sm: 16 },
                          lineHeight: "22px",
                        }}
                      >
                        In our vibrant community, engagement is more than a
                        buzzword—it's a way of life. Through interactive
                        lessons, collaborative projects, and inclusive
                        activities, we ignite a love for learning that
                        captivates our students' minds and hearts, ensuring
                        every day is filled with excitement and discovery.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Advantages of the school */}
        <Grid id="why-us" sx={{ p: mobileScreen ? 2 : laptopScreen ? 5 : 10 }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: mobileScreen ? 20 : 40 }}>
              <span style={{ color: "#2E65AD" }}>Advantages</span>&nbsp; of the
              school
            </Typography>

            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Yelahanka's Largest PreSchool
            </Typography>
            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,

                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid sx={{ p: mobileScreen ? 2 : laptopScreen ? 5 : 10 }}>
            <Grid
              container
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: mobileScreen ? 4 : laptopScreen ? 5 : 8,
              }}
            >
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                  boxShadow: 1,
                }}
              >
                <Grid>
                  <img src={service1} alt="service1" />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  HOLISTIC EDUCATION
                </Typography>
                <Typography sx={{ textAlign: "center", color: "#777777" }}>
                  We offer a well-rounded curriculum that nurtures academic
                  excellence, creativity, and character development.
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                  boxShadow: 1,
                }}
              >
                <Grid>
                  <img src={service2} alt="service2" />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  EXPERIENCED FACULTY
                </Typography>
                <Typography sx={{ textAlign: "center", color: "#777777" }}>
                  Our team of dedicated educators brings years of expertise and
                  passion to inspire and guide our students.
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                  boxShadow: 1,
                }}
              >
                <Grid>
                  <img src={service3} alt="service3" />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  STATE-OF-THE-ART FACILITIES
                </Typography>
                <Typography sx={{ textAlign: "center", color: "#777777" }}>
                  From modern classrooms to advanced learning resources, we
                  provide an environment that fosters exploration and
                  innovation.
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                  boxShadow: 1,
                }}
              >
                <Grid>
                  <img src={service4} alt="service1" />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  VIBRANT COMMUNITY
                </Typography>
                <Typography sx={{ textAlign: "center", color: "#777777" }}>
                  Our inclusive and supportive community encourages
                  collaboration, friendship, and personal growth.
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                  boxShadow: 1,
                }}
              >
                <Grid>
                  <img src={service5} alt="service2" />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  STRONG VALUES
                </Typography>
                <Typography sx={{ textAlign: "center", color: "#777777" }}>
                  We instill values of integrity, respect, and empathy,
                  empowering our students to become compassionate and
                  responsible global citizens.
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  width: 350,
                  height: 300,
                  p: 4,
                  gap: 2,
                  boxShadow: 1,
                }}
              >
                <Grid>
                  <img src={service6} alt="service3" />
                </Grid>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "#2E65AD",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  PERSONALIZED LEARNING
                </Typography>
                <Typography sx={{ textAlign: "center", color: "#777777" }}>
                  We tailor our approach to meet the unique needs and interests
                  of each student, ensuring their full potential is realized.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Yelahanka's Largest PreSchool */}
        <Grid sx={{ p: 0 }}>
          <Grid
            container
            md={12}
            sx={{
              background: "#2E65AD",
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "400px",
              color: "#000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              md={laptopScreen ? 7 : 6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                pt: laptopScreen ? 3 : 5,
              }}
            >
              <Grid
                sx={{
                  width: mobileScreen ? 250 : laptopScreen ? 300 : 350,

                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 10,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    width: mobileScreen ? 250 : laptopScreen ? 300 : 350,
                    borderRadius: 10,
                    py: 2,
                    height: "auto",
                    borderLeft: "10px solid #F6D74E",
                    borderRight: "10px solid #F6D74E",
                    fontSize: mobileScreen ? 14 : laptopScreen ? 16 : 20,
                    color: "#fff",
                  }}
                >
                  Yelahanka's Largest PreSchool
                </Typography>
              </Grid>
              <Grid
                sx={{
                  mt: 7,
                  my: 4,
                  width: mobileScreen ? 275 : 450,

                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 10,
                  color: "#fff",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    width: mobileScreen ? 275 : laptopScreen ? 400 : 450,
                    borderRadius: 10,
                    py: 1,
                    height: "auto",

                    fontSize: mobileScreen ? 25 : laptopScreen ? 30 : 40,
                  }}
                >
                  Admission going on
                </Typography>
              </Grid>
              <Grid
                sx={{
                  my: 1,

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 10,
                  width: mobileScreen ? 250 : laptopScreen ? 550 : 850,
                }}
              >
                <Typography
                  sx={{ textAlign: "center", fontSize: mobileScreen ? 14 : 18 }}
                >
                  Dedicated to Elevating Every Aspect of Your Educational
                  Journey, Ensuring Student Satisfaction Every Step of the Way.
                </Typography>

                <Typography
                  sx={{
                    display: mobileScreen
                      ? "none"
                      : laptopScreen
                      ? "none"
                      : "block",
                    textAlign: "center",
                    fontSize: mobileScreen ? 14 : 18,
                    py: 1,
                  }}
                >
                  At our school, dynamism fuels every aspect of learning,
                  ensuring an environment that adapts and evolves with our
                  students. From interactive lessons to diverse extracurricular
                  activities, we embrace change and innovation to keep education
                  lively, relevant, and engaging..
                </Typography>
              </Grid>
              <Grid>
                <Button
                  sx={{
                    my: 2,

                    background: "#F6D74E",
                    borderRadius: 0,
                    py: 2,
                    px: 2,
                    "&:hover": {
                      background: "#F6D74E",
                    },
                    fontSize: mobileScreen ? 12 : 16,
                    fontWeight: 600,
                  }}
                  onClick={onApplyNowClick}
                >
                  <FontAwesomeIcon
                    icon={faAward}
                    style={{ fontSize: mobileScreen ? 16 : 22 }}
                  />
                  &nbsp; Apply Now!
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={laptopScreen ? 5 : 6}
              xs={12}
              sx={{
                px: 0,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: mobileScreen ? "center" : "flex-end",
                pt: mobileScreen ? 1 : 0,
              }}
            >
              <Grid
                sx={{
                  // my: 2,

                  width: mobileScreen ? "100%" : "100%",
                  height: mobileScreen ? 300 : laptopScreen ? 400 : "100%",
                  boxShadow: 5,
                }}
              >
                <img
                  src={admission}
                  alt="admissionbanner"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: mobileScreen
                      ? "fill"
                      : laptopScreen
                      ? "fill"
                      : "cover",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Views of our guardian */}
        <Grid
          id="views"
          sx={{
            p: mobileScreen ? 2 : laptopScreen ? 5 : 10,
            background: "#f5f5f5",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: mobileScreen ? 20 : laptopScreen ? 30 : 40 }}
            >
              <span style={{ color: "#2E65AD" }}> Views</span>&nbsp;of our
              guardian
            </Typography>

            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : laptopScreen ? 18 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Guardian Voices - A Testament to Our Commitment to Excellence in
              Education.
            </Typography>
            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,

                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            {mobileScreen ? (
              <Carousel
                autoPlay={true}
                swipe={true}
                animation="slide"
                duration={300}
                sx={{ borderRadius: 0 }}
              >
                {data.map((carouselData) => (
                  <Paper
                    elevation={0}
                    sx={{ width: "100%", backgroundColor: "transparent" }}
                    key={carouselData.id}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        p: 1,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Grid
                        item
                        md={5.5}
                        sx={{
                          p: 1,
                          mx: 1,
                        }}
                      >
                        <Grid
                          sx={{
                            background: "#fff",
                            p: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 12,
                            }}
                          >
                            {carouselData.description}
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            py: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid
                            sx={{
                              width: 50,
                              height: 50,
                              borderRadius: 50,
                              mr: 2,
                            }}
                          >
                            <img
                              src={carouselData.profile}
                              alt={`profile-${carouselData.id}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: 50,
                              }}
                            />
                          </Grid>
                          <Grid>
                            <Typography
                              sx={{
                                py: 1,
                                fontSize: 14,

                                fontWeight: 600,
                                color: "#2E65AD",
                                textTransform: "uppercase",
                              }}
                            >
                              {carouselData.name}
                            </Typography>
                            {/* <Typography>{carouselData.designation}</Typography> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </Carousel>
            ) : (
              <Carousel
                autoPlay={true}
                swipe={true}
                animation="slide"
                duration={300}
                sx={{ borderRadius: 0 }}
              >
                {chunkedData.map((chunk, chunkIndex) => (
                  <Paper
                    elevation={0}
                    sx={{ width: "100%", backgroundColor: "transparent" }}
                    key={chunkIndex}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        p: 2,
                        backgroundColor: "transparent",
                      }}
                    >
                      {chunk.map((carouselData, index) => (
                        <Grid
                          item
                          md={5.5}
                          sx={{
                            p: mobileScreen ? 1 : laptopScreen ? 1 : 2,
                            mx: mobileScreen ? 1 : laptopScreen ? 2 : 3,
                          }}
                          key={carouselData.id}
                        >
                          <Grid
                            sx={{
                              background: "#fff",
                              p: mobileScreen ? 2 : laptopScreen ? 3 : 5,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: mobileScreen
                                  ? 12
                                  : laptopScreen
                                  ? 16
                                  : 18,
                              }}
                            >
                              {carouselData.description}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              py: 5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Grid
                              sx={{
                                width: mobileScreen
                                  ? 50
                                  : laptopScreen
                                  ? 65
                                  : 75,
                                height: mobileScreen
                                  ? 50
                                  : laptopScreen
                                  ? 65
                                  : 75,
                                borderRadius: 50,
                                mr: mobileScreen ? 2 : laptopScreen ? 3 : 5,
                              }}
                            >
                              <img
                                src={carouselData.profile}
                                alt={`profile-${carouselData.id}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: 50,
                                }}
                              />
                            </Grid>
                            <Grid>
                              <Typography
                                sx={{
                                  py: 1,
                                  fontSize: mobileScreen
                                    ? 14
                                    : laptopScreen
                                    ? 16
                                    : 18,
                                  fontWeight: 600,
                                  color: "#2E65AD",
                                  textTransform: "uppercase",
                                }}
                              >
                                {carouselData.name}
                              </Typography>
                              {/* <Typography>{carouselData.designation}</Typography> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                ))}
              </Carousel>
            )}
          </Grid>
        </Grid>
        {/* addmissionform */}
        <Grid
          id="admission"
          sx={{ p: mobileScreen ? 2 : laptopScreen ? 8 : 10 }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: mobileScreen ? 20 : 40 }}>
              Quickly&nbsp;
              <span style={{ color: "#2E65AD" }}>admit</span>
              &nbsp;here
            </Typography>

            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
              />
            </Grid>
            <Typography
              variant="p"
              sx={{
                fontSize: mobileScreen ? 16 : 20,
                textAlign: "center",
                px: mobileScreen ? 3 : 0,
                lineHeight: "20px",
              }}
            >
              Join the Podar Prep Family - Where Learning Feels Like Fun!
            </Typography>
            <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
              <Divider
                sx={{
                  background: "#2E65AD",
                  width: 150,

                  textAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              py: mobileScreen ? 2 : 5,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={4}
                sx={{ width: mobileScreen ? 300 : 800 }}
              >
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter Your Name"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{
                      style: {
                        height: mobileScreen ? 8 : 22,
                        fontSize: mobileScreen ? 14 : 16,
                        background: "#fff",
                        borderRadius: 0,
                      },
                    }}
                    variant="outlined"
                    sx={{
                      margin: 0,
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter Your Phone Number"
                    type="tel"
                    required
                    value={phone}
                    onChange={handlePhoneChange}
                    inputProps={{
                      style: {
                        height: mobileScreen ? 8 : 22,
                        fontSize: mobileScreen ? 14 : 16,
                        background: "#fff",
                        borderRadius: 0,
                      },
                    }}
                    variant="outlined"
                    sx={{
                      margin: 0,
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter Your Message"
                    multiline
                    rows={4}
                    // required
                    value={message}
                    // onChange={(e) => setMessage(e.target.value)}
                    inputProps={{
                      style: {
                        fontSize: mobileScreen ? 14 : 16,
                        background: "#fff",
                        borderRadius: 0,
                      },
                    }}
                    variant="outlined"
                    sx={{
                      margin: 0,
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: mobileScreen ? 30 : 50,
                      fontWeight: 600,
                      borderRadius: 0,
                      background: "#2E65AD",
                      "&:hover": {
                        background: "#2E65AD",
                      },
                      fontSize: mobileScreen ? 14 : 16,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{
                        marginRight: 8,
                        fontSize: mobileScreen ? 16 : 20,
                      }}
                    />
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
