import {
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faXmark } from "@fortawesome/free-solid-svg-icons";
import admission from "../../src/assets/images/admission.jpeg";

const AdmissionFormPopup = ({ open, onClose }) => {
  const mobileScreen = useMediaQuery(
    "(min-width: 360px) and (max-width: 599px)"
  );
  const laptopScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1440px)"
  );

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setPhone(numericValue);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const ipInfoResponse = await fetch("https://ipinfo.io/json");
      const ipInfo = await ipInfoResponse.json();

      const data = {
        fullName: name,
        phoneNumber: phone,
        ip: ipInfo.ip,
        city: ipInfo.city,
        region: ipInfo.region,
        country: ipInfo.country,
        loc: ipInfo.loc,
      };

      const response = await fetch(
        "https://api.podarprepyelahanka.com/addLead",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      setName("");
      setPhone("");
      setMessage("");
    } catch (error) {}
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#ffffff",
          // padding: 4,
          borderRadius: 1,
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 1,
            right: mobileScreen ? 2 : 5,
            color: "#2E65AD",
            width: mobileScreen ? 40 : "auto",
            height: mobileScreen ? 40 : "auto",
            backgroundColor: mobileScreen
              ? "rgba(255,255,255,0.8)"
              : "transparent",
            "&:hover": {
              backgroundColor: mobileScreen
                ? "rgba(255,255,255,0.8)"
                : "transparent",
            },
          }}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
        <Grid container md={12} xs={12}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: mobileScreen ? "center" : "flex-start",
            }}
          >
            <Grid
              sx={{
                width: mobileScreen ? 330 : laptopScreen ? 950 : 800,
                height: mobileScreen ? 240 : laptopScreen ? 480 : 560,
              }}
            >
              <img
                src={admission}
                alt="admissionbanner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: mobileScreen
                    ? "cover"
                    : laptopScreen
                    ? "fill"
                    : "fill",
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              px: laptopScreen ? 2 : 4,
              py: mobileScreen ? 2 : laptopScreen ? 0 : 0,
            }}
          >
            <Grid
              sx={{
                display:  "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "#000",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: mobileScreen ? 20 : laptopScreen ? 25 : 28,
                  fontWeight: 600,
                }}
              >
                Quickly&nbsp;
                <span style={{ color: "#2E65AD" }}>admit</span>
                &nbsp;here
              </Typography>

              <Grid sx={{      py: mobileScreen ? 2 : laptopScreen ? 3 : 3 }}>
                <Divider
                  sx={{
                    background: "#2E65AD",
                    width: 150,
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  display: mobileScreen ? "none" : "block",
                  fontSize: mobileScreen ? 16 : laptopScreen ? 15 : 17,
                  textAlign: "center",
                  px: mobileScreen ? 3 : 0,
                  lineHeight: "20px",
                }}
              >
                Join the Podar Prep Family - Where Learning Feels Like Fun!
              </Typography>

              <Grid sx={{ display: mobileScreen ? "none" : "block", py: mobileScreen ? 2 : laptopScreen ? 3 : 3 }}>
                <Divider
                  sx={{
                    background: "#2E65AD",
                    width: 150,
                    textAlign: "center",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: mobileScreen ? 2 : laptopScreen ? 3 : 4,
              }}
            >
              <form onSubmit={handleSubmit}>
                <Grid container spacing={mobileScreen?1.5:3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter Your Name"
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      inputProps={{
                        style: {
                          height: mobileScreen ? 7 : laptopScreen ? 10 : 10,
                          fontSize: mobileScreen ? 12 : laptopScreen ? 12 : 13,
                          background: "#fff",
                          borderRadius: 0,
                        },
                      }}
                      variant="outlined"
                      sx={{
                        margin: 0,
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter Your Ph.No"
                      type="tel"
                      required
                      value={phone}
                      onChange={handlePhoneChange}
                      inputProps={{
                        maxLength: 10,
                        style: {
                          height: mobileScreen ? 7 : laptopScreen ? 10 : 10,
                          fontSize: mobileScreen ? 12 : laptopScreen ? 12 : 13,
                          background: "#fff",
                          borderRadius: 0,
                        },
                      }}
                      variant="outlined"
                      sx={{
                        margin: 0,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter Your Message"
                      multiline
                      rows={mobileScreen?2:3}
                      value={message}
                      inputProps={{
                        style: {
                     
                          fontSize: mobileScreen ? 12 : laptopScreen ? 12 : 13,
                          background: "#fff",
                          borderRadius: 0,
                        },
                      }}
                      variant="outlined"
                      sx={{
                        margin: 0,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mb:mobileScreen ? 0 : laptopScreen ? 2:0,
                        height: mobileScreen ? 30 : laptopScreen ? 35 : 40,
                        fontWeight: 600,
                        borderRadius: 0,
                        background: "#2E65AD",
                        "&:hover": {
                          background: "#2E65AD",
                        },
                        fontSize: mobileScreen ? 14 : laptopScreen ? 14 : 15,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{
                          marginRight: 8,
                          fontSize: mobileScreen ? 16 : laptopScreen ? 16 : 18,
                        }}
                      />
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AdmissionFormPopup;
