import Header from "./components/Header";
import "../src/assets/css/style.css";
import HomePage from "./components/HomePage";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import AdmissionFormPopup from "./components/AdmissionFormPopup";

function App() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleOpen = () => {
      if (!open) {
        setOpen(true);
      }
    };
    const interval = setInterval(handleOpen, 30000);
    return () => clearInterval(interval);
  }, [open]);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Header />
      <HomePage onApplyNowClick={handleOpenModal} />
      <Footer />
      <AdmissionFormPopup open={open} onClose={handleClose} />
    </div>
  );
}

export default App;
