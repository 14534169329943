import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";

import logo from "../../src/assets/images/Podar-prep-logo.png";


import MenuIcon from "@mui/icons-material/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const laptopScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1440px)"
  );
  const HeaderHeight =laptopScreen?105: 115;

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -HeaderHeight;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const [activeLink, setActiveLink] = useState("home");
  const isMobile = useMediaQuery("(max-width:1023px)");
  const [isCardVisible, setIsCardVisible] = useState(false);

  const handleMenuItemClick = () => {
    setIsCardVisible(true);
  };
  const handleCloseMenuItem = () => {
    setIsCardVisible(false);
  };

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 10,   background: "#fff", }}>
      {isMobile ? (
        <Grid
          sx={{
            background: "#fff",
            width: "100%",
            height: 75,
            "@media (min-width: 768px) and (max-width:1023px)": {
              height: 100,
            },
          }}
        >
          <Grid
            sx={{
              width: "100%",
              height: 75,
              boxShadow: 2,
              "@media (min-width: 768px) and (max-width:1023px)": {
                height: 100,
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Grid
              sx={{
                width: 75,
                height: 60,
                cursor: "pointer",
                "@media (min-width: 768px) and (max-width:900px)": {
                  height: 60,
                  width: 100,
                },
                mx: 1,
              }}
         
            >
              <img
                src={logo}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="logo"
              />
            </Grid>
            <Grid
              sx={{
                width: "100%",

                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                color: "#2E65AD",
              }}
            >
              {!isCardVisible ? (
                <MenuIcon
                  sx={{
                    fontSize: 27,
                    mx: 1,

                    transition: "transform 0.5s ease-in-out",
                    "@media (min-width: 768px) and (max-width:1023px)": {
                      fontSize: 35,
                    },
                  }}
                  onClick={handleMenuItemClick}
                />
              ) : (
                <Grid></Grid>
              )}
            </Grid>
          </Grid>

          {isCardVisible && (
            <Grid
              sx={{
                position: "fixed",
                top: 0,
                left: isCardVisible ? 0 : "-75%",
                width: "75%",
                height: "auto",
                transition: "left 2s ease-in-out",
                backgroundColor: "#fff",
                zIndex: 5,
              }}
            >
              <Card elevation={2} sx={{ borderRadius: 0, width: "100%" }}>
                <Grid>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                
                      justifyContent: "space-around",
                     
                      "@media (min-width: 360px) and (max-width:767px)": {
                        my: "5px",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",

                        cursor: "pointer",
                      }}
              
                    >
                      <Grid
                        sx={{
                          py:1,
                          width: 65,
                          height: 80,
                          "@media (min-width: 768px) and (max-width:900px)": {
                            width: 150,
                            height: 40,
                          },
                          mx: 2,
                        }}
                      >
                        <img
                          src={logo}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          alt="logo"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{
                        color: "#2E65AD",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      onClick={handleCloseMenuItem}
                    >
                      <CloseOutlinedIcon
                        sx={{
                          fontSize: 27,
                          mx: 3,
                          transition: "transform 0.5s ease-in-out",
                          "@media (min-width: 768px) and (max-width:1023px)": {
                            fontSize: 35,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: 250,
                    }}
                  >
                    <List sx={{ cursor: "pointer" }}>
                      <ListItem
                        sx={{
                          fontSize: 15,
                          mx: 2,

                          fontWeight: 600,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          "&:hover": {
                            color: "#1d1a57",
                          },
                          "@media (min-width: 768px) and (max-width:1023px)": {
                            fontSize: 18,
                          },
                        }}
                        onClick={() => {
                        
                          handleCloseMenuItem();
                        }}
                      >
                        <ListItemText
                          onClick={() => scrollToSection("home")}
                          sx={{
                            color:
                              activeLink === "home" ? "#2E65AD" : "#777777",
                            "&:hover": {
                              color: "#2E65AD",
                            },
                            py: 1,
                          }}
                        >
                          Home
                        </ListItemText>
                        <ListItemText
                          onClick={() => scrollToSection("about")}
                          sx={{
                            color:
                              activeLink === "about" ? "#2E65AD" : "#777777",
                            "&:hover": {
                              color: "#2E65AD",
                            },
                            py: 1,
                          }}
                        >
                          About
                        </ListItemText>
                        <ListItemText
                          onClick={() => scrollToSection("pillars")}
                          sx={{
                            color:
                              activeLink === "Pillars" ? "#2E65AD" : "#777777",
                            "&:hover": {
                              color: "#2E65AD",
                            },
                            py: 1,
                          }}
                        >
                          Pillars
                        </ListItemText>
                        <ListItemText
                          onClick={() => scrollToSection("why-us")}
                          sx={{
                            color:
                              activeLink === "why-us" ? "#2E65AD" : "#777777",
                            "&:hover": {
                              color: "#2E65AD",
                            },
                            py: 1,
                          }}
                        >
                          Why Us
                        </ListItemText>
                        <ListItemText
                          onClick={() => scrollToSection("views")}
                          sx={{
                            color:
                              activeLink === "views" ? "#2E65AD" : "#777777",
                            "&:hover": {
                              color: "#2E65AD",
                            },
                            py: 1,
                          }}
                        >
                          Views
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid sx={{ width: "100%", height:laptopScreen?105: 115 ,display:'flex',alignItems:'center'}}>
          <Grid
            container
            md={12}
            sx={{ py: 1, background: "#fff", boxShadow: 3, height:laptopScreen?105:  115 }}
          >
            <Grid item md={3}  sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",}} >
              <Grid sx={{ width:laptopScreen?90:  100, height:laptopScreen?90:  100 }}>
                <img
                  src={logo}
                  alt="podarpreplogo"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                color: activeLink === "home" ? "#2E65AD" : "#777777",
              }}
            >
              <Typography
                sx={{
                  fontSize:laptopScreen?18:  20,
                  "&:hover": {
                    color: "#2E65AD",
                  },
                }}
                onClick={() => scrollToSection("home")}
              >
                Home
              </Typography>
              <Typography
                sx={{
                  fontSize:laptopScreen?18:  20,
                  color: activeLink === "about" ? "#2E65AD" : "#777777",
                  "&:hover": {
                    color: "#2E65AD",
                  },
                }}
                onClick={() => scrollToSection("about")}
              >
                About
              </Typography>
              <Typography
                sx={{
                  fontSize:laptopScreen?18:  20,
                  color: activeLink === "pillars" ? "#2E65AD" : "#777777",
                  "&:hover": {
                    color: "#2E65AD",
                  },
                }}
                onClick={() => scrollToSection("pillars")}
              >
                Pillars
              </Typography>
              <Typography
                sx={{
                  fontSize:laptopScreen?18:  20,
                  color: activeLink === "why-us" ? "#2E65AD" : "#777777",
                  "&:hover": {
                    color: "#2E65AD",
                  },
                }}
                onClick={() => scrollToSection("why-us")}
              >
                Why Us
              </Typography>
              <Typography
                sx={{
                  fontSize:laptopScreen?18:  20,
                  color: activeLink === "views" ? "#2E65AD" : "#777777",
                  "&:hover": {
                    color: "#2E65AD",
                  },
                }}
                onClick={() => scrollToSection("views")}
              >
                Views
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Grid
                sx={{
                  background: "#2E65AD",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  borderRadius: 2,
                  fontWeight: 600,
                  gap: 1,
                  boxShadow: 3,
                }}
              >
             <FontAwesomeIcon icon={faPhoneVolume} />
                <a
                  href="tel:+919844565757"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: 18,
                  }}
                >
                  +91 9844565757
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Header;
