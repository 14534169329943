import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const mobileScreen = useMediaQuery(
    "(min-width: 360px) and (max-width: 599px)"
  );
  const laptopScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1440px)"
  );

  return (
    <div>
      <Grid sx={{ background: "#F6D74E", py: 5, color: "#000" }}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#000",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: mobileScreen ? 20 : 40 }}>
            Now&nbsp;<span style={{ color: "#2E65AD" }}>Subscribe</span>
            &nbsp;Us!
          </Typography>

          <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
            <Divider
              sx={{ background: "#2E65AD", width: 150, textAlign: "center" }}
            />
          </Grid>
          <Typography
            variant="p"
            sx={{
              fontSize: mobileScreen ? 16 : 20,
              textAlign: "center",
              px: mobileScreen ? 3 : laptopScreen ? 10 : 0,
              lineHeight: "30px",
            }}
          >
            Join the fun-filled journey of learning and laughter at Yelahanka's
            biggest Podar Prep preschool, where every day is an exciting new
            chapter for your little one.
          </Typography>
          <Grid sx={{ py: mobileScreen ? 2 : 3 }}>
            <Divider
              sx={{
                background: "#2E65AD",
                width: 150,
                textAlign: "center",
              }}
            />
          </Grid>
        </Grid>
        <Grid>
          <form
          // onSubmit={handleSubmit}
          >
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item>
                <TextField
                  placeholder="Enter Your Mail"
                  type="email"
                  required
                  inputProps={{
                    disableUnderline: true,
                    style: {
                      height: mobileScreen ? 1 : 22,
                      fontSize: mobileScreen ? 12 : 16,
                      background: "#fff",
                      borderRadius: 0,
                    },
                  }}
                  sx={{
                    width: mobileScreen ? 200 : 500,
                    margin: 0,
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    height: mobileScreen ? 32 : 55,
                    borderRadius: 0,

                    width: mobileScreen ? 75 : 200,
                    background: "#2E65AD",
                    margin: 0,
                    "&:hover": {
                      background: "#2E65AD",
                    },
                    fontSize: mobileScreen ? 10 : 18,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRocket}
                    style={{ fontSize: mobileScreen ? 10 : 20 }}
                  />
                  &nbsp;Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid
          sx={{
            py: mobileScreen ? 2 : 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            md={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: 5,
              height: mobileScreen ? "auto" : 300,
            }}
          >
            <Grid item md={3} xs={12} sx={{ px: mobileScreen ? 4 : 0 }}>
              <Typography sx={{ fontSize: mobileScreen ? 16 : 22 }}>
                About us
              </Typography>
              <Grid sx={{ py: mobileScreen ? 1 : 3 }}>
                <Divider
                  sx={{
                    background: "#2E65AD",
                    width: 150,
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                  lineHeight: 2,
                }}
              >
                Join the fun-filled journey of learning and laughter at
                Yelahanka's biggest Podar Prep preschool, where every day is an
                exciting new chapter for your little one.
              </Typography>
            </Grid>
            <Grid item md={3} xs={12} sx={{ px: mobileScreen ? 4 : 0 }}>
              <Typography sx={{ fontSize: mobileScreen ? 16 : 22 }}>
                Quick links
              </Typography>
              <Grid sx={{ py: mobileScreen ? 1 : 3 }}>
                <Divider
                  sx={{
                    background: "#2E65AD",
                    width: 150,
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 10,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",

                    gap: 2,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;About Us
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Pricing
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Faqs
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Services
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Sustainability
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",

                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;History
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Awards
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Community
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Events
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                      "&:hover": {
                        color: "#2E65AD",
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretRight} />
                    &nbsp;&nbsp;Methods
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12} sx={{ px: mobileScreen ? 4 : 0 }}>
              <Typography sx={{ fontSize: mobileScreen ? 16 : 22 }}>
                Contact Info
              </Typography>
              <Grid sx={{ py: mobileScreen ? 1 : 3 }}>
                <Divider
                  sx={{
                    background: "#2E65AD",
                    width: 150,
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    lineHeight: "25px",
                    fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18,
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Address:</span>&nbsp;CA
                  Site9, A Sector, Next to KK Kids Hospital, Opp. to MEC School,
                  Yelahanka New Town, Bangalore - 64.
                </Typography>
                <Typography
                  sx={{ fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18 }}
                >
                  <span style={{ fontWeight: 600 }}>Phone:</span>&nbsp;+91 98445
                  65757
                </Typography>
                <Typography
                  sx={{ fontSize: mobileScreen ? 14 : laptopScreen ? 17 : 18 }}
                >
                  <span style={{ fontWeight: 600 }}>Email:</span>
                  &nbsp;info@podarprepyelahanka.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sx={{
            borderTop: "1px solid #777",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <Typography
            sx={{ fontSize: mobileScreen ? 11 : laptopScreen ? 16 : 18 }}
          >
            Copyright © 2024&nbsp;
            <span
              style={{
                color: "#2E65AD",
                fontSize: mobileScreen ? 11 : laptopScreen ? 16 : 18,
              }}
            >
              PodarPrepYelahanka,
            </span>
            &nbsp;All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
